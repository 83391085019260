import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Footer extends Component {
  state = {
    email: ''
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  }

  render() {
    const {t, i18n} = this.props;
    const quickLinks = [
      {path: '/privacy', title: t('footer.btns.privacy')},
      {path: '/terms', title: t('footer.btns.terms')},
      //{path: '/bugs', title: t('footer.btns.bug')}
    ];

    const otherLinks = [

    ];

    return (
      <footer className="bg-one">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="footer-logo">
                <a href="index.html"><img src="images/logo/logo.png" alt="Logo"/></a>
                <h5><a href="#" className="tran3s">support@ufast.co</a></h5>
                <h6 className="p-color">+886 968. 605. 279‬</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer-list">
              <h4>{t('footer.info')}</h4>
              <ul>
                {quickLinks.map(({path, title}) => {
                  return (
                    <li key={title}><a href={path} className="tran3s">{title}</a></li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer-list">
              <h4>{t('footer.links')}</h4>
              <ul>
            {/*<li><a target='_blank' rel="noopener noreferrer" href="/support" className="tran3s">{t('footer.btns.support')}</a></li>*/}
                <li><a target='_blank' rel="noopener noreferrer" href="https://medium.com/ufast" className="tran3s">{t('footer.btns.blog')}</a></li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 footer-subscribe">
              <h4>{t('footer.subscribe.title')}</h4>
              <form action="https://mirongroup.us20.list-manage.com/subscribe/post" method="POST" name="mc-embedded-subscribe-form" target="_blank" noValidate>
                <input type="hidden" name="u" value="37efc48db297d2d94359a983f"/>
                <input type="hidden" name="id" value="5a03da7046"/>
                <input id='mce-Email' name="EMAIL" type="email" placeholder={t('footer.subscribe.placeholder')} required autoCapitalize="off" autoCorrect="off" value={this.state.email} onChange={this.handleChangeEmail}/>
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_37efc48db297d2d94359a983f_5a03da7046" tabIndex="-1"/></div>
                <input type="submit" value={t('footer.subscribe.btn')} name="subscribe" id="mc-embedded-subscribe" className="button"/>
              </form>
              <ul>
                <li><a target='_blank' rel="noopener noreferrer" href="https://facebook.com/ufastco" className="tran3s"><i className="fab fa-facebook" aria-hidden="true"></i></a></li>
                <li><a target='_blank' rel="noopener noreferrer" href="https://twitter.com/ufastco" className="tran3s"><i className="fab fa-twitter" aria-hidden="true"></i></a></li>
                <li><a target='_blank' rel="noopener noreferrer" href="https://instagram.com/ufastco" className="tran3s"><i className="fab fa-instagram" aria-hidden="true"></i></a></li>
              {/*<li><a target='_blank' rel="noopener noreferrer" href="https://line.me/R/ti/p/@ufastco" className="tran3s"><i className="fab fa-line" aria-hidden="true"></i></a></li>*/}
              </ul>
            </div>
          </div> {/*.row*/}

          <div className="bottom-footer clearfix">
            <ul className="float-right">
              {/*<li><h3><span className="timer p-color" data-from="0" data-to="5123" data-speed="5000" data-refresh-interval="50">0</span> Packages Delivered</h3></li>
            <li><h3><span className="timer p-color" data-from="0" data-to="300" data-speed="5000" data-refresh-interval="50">0</span> Drivers</h3></li>*/}
            </ul>
            <p className="float-left">&copy; {new Date().getFullYear()} <a href="/" className="tran3s p-color">Ufast - 郵飛</a>. {t('footer.rights')}</p>
          </div>
        </div> {/*.container*/}
      </footer>
    );
  }
}

export default withTranslation()(Footer);
