import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { initTheme } from '../utils';

class Drivers extends Component {
  componentDidMount() {
    initTheme();
  }

  render() {
    const {t, i18n} = this.props;
    return (
      <div>
        {/*
        =============================================
          Theme Inner Banner
        ==============================================
        */}
        <div className="inner-page-banner">
          <div className="opacity">
            <h1>{t('drivers.banner.title')}</h1>
            <ul>
              <li><Link to='/'>{t('header.btns.home')}</Link></li>
              <li>/</li>
              <li>{t('header.btns.drivers')}</li>
            </ul>
          </div> {/*.opacity with background commented out*/}
        </div> {/*inner-page-banner*/}



        {/*
        =============================================
          Our Service Details
        ==============================================
        */}
        <div className="service-details">
          <div className="container">
            <div className="box-wrapper">
              <img src="images/drivers/driver1.jpg" alt="" className="feature-image"/>
              <div className="title clearfix">
                <h3 className="float-left">{t('drivers.details.section1.title')}</h3>
                <a href="https://mailchi.mp/ufast/drivers" className="loan float-right">{t('drivers.details.section1.btn')}</a>
              </div> {/*.title*/}
              <div className="top-text">
                <h4>{t('drivers.details.section1.subtitle')}</h4>
                <div className="row">
                  <div className="col-md-6 wow fadeInLeft"><p>{t('drivers.details.section1.body')}</p></div>
                {/*<div className="col-md-6 wow fadeInRight"><p>Make money reliably.</p></div>*/}
                </div>
              </div> {/*.top-text*/}

              <div className="middle-text list-box-text wow fadeInUp">
                <h3>{t('drivers.details.section2.title')}</h3>
                <p></p>
                <ul>
                  <li>
                    <h4>{t('drivers.details.section2.feature1.title')}</h4>
                    <p>{t('drivers.details.section2.feature1.body')}</p>
                  </li>
                  <li>
                    <h4>{t('drivers.details.section2.feature2.title')}</h4>
                    <p>{t('drivers.details.section2.feature2.body')}</p>
                  </li>
                  <li>
                    <h4>{t('drivers.details.section2.feature3.title')}</h4>
                    <p>{t('drivers.details.section2.feature3.body')}</p>
                  </li>
                </ul>
              </div> {/*.middle-text*/}


            </div> {/*.box-wrapper*/}
          </div> {/*.container*/}
        </div> {/*.service-details*/}
      </div>
    );
  }
}

export default withTranslation()(Drivers);
