export const initTheme = () => {
  //window.removePlaceholder();
  window.BannerSlider();
  window.wowAnimation();
  //window.mixitupGallery();
  //window.testimonialSlider();
  //window.portfolioSlider();
  //window.partnersLogo();
  //window.CounterNumberChanger();
  //window.scrollToTop();
  //window.contactFormValidation();
  window.closeSuccessAlert();
  window.themeAccrodion();
  //window.priceRanger();
  //window.productValue();
  //window.productSlider();
  //window.youtubeVideo();
}
