import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Loader, Header, Footer, ScrollToTop } from './components';
import { Home, Drivers, Error } from './containers';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="main-page-wrapper">
          <ScrollToTop />
          <Helmet>
            <title>Ufast - 讓您的包裹郵件立刻起飛</title>
          </Helmet>
          <Suspense fallback={<Loader />}>
      			<Header />
            <Switch>
              <Route path='/' exact component={Home}/>
              <Route path='/drivers' component={Drivers}/>
              <Route component={Error} />
            </Switch>
            <Footer />

            {/*Sign-Up Modal*/}
            <div className="modal fade signUpModal theme-modal-box" role="dialog">
              <div className="modal-dialog">
                  {/*Modal content*/}
                  <div className="modal-content">
                    <div className="modal-body">
                        <h3>Login with Social Networks</h3>
                        <ul className="clearfix">
                          <li className="float-left"><a href="#"><i className="fab fa-facebook" aria-hidden="true"></i> facebook</a></li>
                          <li className="float-left"><a href="#"><i className="fab fa-google-plus" aria-hidden="true"></i> Google</a></li>
                          <li className="float-left"><a href="#"><i className="fab fa-twitter" aria-hidden="true"></i> Twitter</a></li>
                          <li className="float-left"><a href="#"><i className="fab fa-line" aria-hidden="true"></i> Linkedin</a></li>
                        </ul>
                        <form action="#">
                          <h6><span>or</span></h6>
                          <div className="wrapper">
                            <input type="text" placeholder="Username or Email"/>
                            <input type="password" placeholder="Password"/>
                            <ul className="clearfix">
                          <li className="float-left">
                            <input type="checkbox" id="remember"/>
                            <label htmlFor="remember">Remember Me</label>
                          </li>
                          <li className="float-right"><a href="#" className="p-color">Lost Your Password?</a></li>
                        </ul>
                        <button className="p-bg-color hvr-trim-two">Login</button>
                          </div>
                        </form>
                    </div> {/*.modal-body*/}
                  </div> {/*.modal-content*/}
              </div> {/*.modal-dialog*/}
            </div> {/*.signUpModal*/}

            <button className="scroll-top tran3s">
              <i className="fa fa-angle-up" aria-hidden="true"></i>
            </button>

            <div id="svag-shape">
              <svg height="0" width="0">
                <defs>
                    <clipPath id="shape-one">
                      <path fillRule="evenodd"  fill="rgb(168, 168, 168)"
                 d="M343.000,25.000 C343.000,25.000 100.467,106.465 25.948,237.034 C-30.603,336.119 15.124,465.228 74.674,495.331 C134.224,525.434 212.210,447.071 227.280,432.549 C242.349,418.028 338.889,359.517 460.676,506.542 C582.737,653.896 725.650,527.546 751.000,478.000 C789.282,403.178 862.636,-118.314 343.000,25.000 Z"/>
                    </clipPath>
                </defs>
              </svg>
            </div>
          </Suspense>
    		</div>
      </Router>
    );
  }
}

export default App;
