import React from 'react';

export default (props) => (
  <div id="loader-wrapper">
    <div id="loader">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
);
