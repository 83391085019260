import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { initTheme } from '../utils';

class Home extends Component {
  componentDidMount() {
    initTheme();
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div>
      {/*
      =============================================
        Theme Main Banner
      ==============================================
      */}
        <div id="theme-main-banner" className="banner-one">
          <div data-src="images/home/slide-1.jpg">
            <div className="camera_caption">
              <div className="container">
                <h5 className="wow fadeInUp animated">{t('home.banner1.title')}</h5>
                <h1 className="wow fadeInUp animated" data-wow-delay="0.2s"><span>{t('home.banner1.body1')}</span><br/><span>{t('home.banner1.body2')}</span><br/><span>{t('home.banner1.body3')}</span></h1>
                <a href="#" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s">{t('home.banner1.btn')}</a>
                <div className="wow fadeInRight animated image-shape-one" data-wow-delay="0.33s">
                  <img width="778" height="576" src="images/home/image1.jpg" className="banner-img-one" />
                </div>
                <div className="wow fadeInRight animated image-shape-two" data-wow-delay="0.39s"><div className="theme-shape-two"></div></div>
              </div> {/*.container*/}
            </div> {/*.camera_caption*/}
          </div>
          <div data-src="images/home/banner1.jpg">
            <div className="camera_caption">
              <div className="container">
                <h5 className="wow fadeInUp animated">{t('home.banner2.title')}</h5>
                <h1 className="wow fadeInUp animated" data-wow-delay="0.2s"><span>{t('home.banner2.body1')}</span><br/><span>{t('home.banner2.body2')}</span><br/></h1>
                <Link to="/drivers" className="tran3s hvr-trim wow fadeInUp animated p-bg-color button-one" data-wow-delay="0.3s">{t('home.banner2.btn')}</Link>
                <div className="wow fadeInRight animated image-shape-one" data-wow-delay="0.33s">
                  <svg  version="1.1" className="Layer_1" xmlns="http://www.w3.org/2000/svg" width="778" height="576">
                    <clipPath className="clip1">
                      <use  />
                    </clipPath>
                    <g clipPath="url(#shape-one)">
                      {/*<image width="778" height="576" href="images/home/1.jpg" className="banner-img-one">
                      </image>*/}
                    </g>
                  </svg>
                </div>
                <div className="wow fadeInRight animated image-shape-two" data-wow-delay="0.39s"><div className="theme-shape-two"></div></div>
              </div> {/*.container*/}
            </div> {/*.camera_caption*/}
          </div>
        </div> {/*#theme-main-banner*/}


        {/*
        =============================================
          More About Us
        ==============================================
        */}
        <div className="more-about-us">
          <div className="image-box">
            <img width="854" height="632" src="images/home/app.jpg" className="image-shape" />
          </div>
          <div className="theme-shape-three"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-offset-5">
                <div className="main-content" style={{marginBottom: '50px'}}>
                  <h2>{t('home.app.title')}</h2>
                  <div className="main-wrapper">
                    <h4>{t('home.app.heading')}</h4>
                    <p>{t('home.app.body')}</p>
                    <div style={{height: '100px'}}></div>
                    <div className="button-wrapper p-bg-color">
                      <span>{t('home.app.btn.title')}</span>
                    {/*<a href="" className="hvr-icon-wobble-horizontal">{t('home.app.btn.subtitle')} <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>*/}
                    </div> {/*.button-wrapper*/}
                  </div> {/*.main-wrapper*/}
                </div> {/*.main-content*/}
              </div> {/*.col-*/}
            </div> {/*.row*/}
          </div> {/*.container*/}
        </div> {/*.more-about-us*/}

      </div>
    );
  }
}

export default withTranslation()(Home);
