import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

class Header extends Component {
  activePath = (path) => (this.props.location.pathname === path)

  changeLang = (newLang) => {
    const {i18n} = this.props;
    i18n.changeLanguage(newLang);
  }

  render() {
    const {t, i18n} = this.props;
    const lang = i18n.language;

    let langOpts = 'en-US';
    let langChoice = 'English';

    if (lang.split('-')[0] === 'en') {
      langOpts = 'zh-TW';
      langChoice = '中文';
    }

    const inlinks = [
      {path: '/', title: t('header.btns.home')},
      {path: '/drivers', title: t('header.btns.drivers')}
    ];

    const outlinks = [
      //{path: 'https://zendesk.com', title: t('header.btns.support')},
      {path: 'https://medium.com/ufast', title: t('header.btns.blog')}
    ];

    const changeLangFunc = (e) => {
      e.preventDefault();
      this.changeLang(langOpts);
    }

    return (
      <header className="theme-menu-wrapper full-width-menu">
        <Helmet>
          <title key='meta-title'>{t('document.title')}</title>
        </Helmet>
        {/*<meta key='meta-description' name="description" content="Nested component" />*/}
        <div className="header-wrapper">
          <div className="clearfix">
            {/*Logo*/}
            <div className="logo float-left tran4s"><Link to="/"><img src="images/logo/logo.png" alt="Logo"/></Link></div>

            {/*============================ Theme Menu =========================*/}
            <nav className="theme-main-menu float-right navbar" id="mega-menu-wrapper">
              {/*Brand and toggle get grouped for better mobile display*/}
               <div className="navbar-header">
                 <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                   <span className="sr-only">{t('header.btns.togglenav')}</span>
                   <span className="icon-bar"></span>
                   <span className="icon-bar"></span>
                   <span className="icon-bar"></span>
                 </button>
               </div>
               {/* Collect the nav links, forms, and other content for toggling*/}
               <div className="collapse navbar-collapse" id="navbar-collapse-1">
                <ul className="nav">
                  {inlinks.map(({path, title}) => {
                    return (
                      <li key={title} className={classNames('menu-list', {active: this.activePath(path)})}>
                        <Link to={path} className="tran3s">{title}</Link>
                      </li>
                    );
                  })}
                  {outlinks.map(({path, title}) => {
                    return (
                      <li key={title} className={classNames('menu-list', {active: this.activePath(path)})}>
                        <a target='_blank' rel="noopener noreferrer" href={path} className="tran3s">{title}</a>
                      </li>
                    );
                  })}
                  <li className='menu-list'>
                    <a href='#' className="" onClick={changeLangFunc}>{langChoice}</a>
                  </li>
                {/*<li className="login-button"><a href="#" className="tran3s">{t('header.btns.package')}</a></li>*/}
                </ul>
               </div>{/*.navbar-collapse*/}
            </nav> {/*.theme-main-menu*/}
          </div> {/*.clearfix*/}
        </div>
      </header>
    );
  }
}

export default withTranslation()(withRouter(Header));
