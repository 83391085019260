import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="what-we-do" style={{height: '50vh', display: 'flex', paddingBottom: 0}}>
        <div className="container" style={{margin: 'auto'}}>
          <h3>{t('error.title')}</h3>
          <h6 style={{marginBottom: '0px'}}>{t('error.subtitle1')} <Link to='/'>{t('error.home')}</Link> {t('error.subtitle2')}</h6>
        </div>
      </div>
    </div>
  );
};

export default Error;
